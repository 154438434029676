import request from '../utils/request'

// 预约 信息获取
export function getReserveConfig(data) {
    return request({
        url: "/reserve/config/get",
        method: "post",
        data
    })
}

// 预约 提交预约
export function ReserveCreateUpdate(data) {
    return request({
        url: "/reserve/create-update",
        method: "post",
        data
    })
}

// 预约 列表
export function ReserveList(data) {
    return request({
        url: "/reserve2/user-reserve/query",
        method: "post",
        data
    })
}


// 获取预约项目
export function ReserveProject(data) {
    return request({
        url: "/reserve2/project/list",
        method: "post",
        data
    })
}

// 获取预约详情
export function reserveDetails(data) {
    return request({
        url: "/reserve2/user-reserve/get",
        method: "post",
        data
    })
}

// 获取预约 q取消
export function reserveCancel(data) {
    return request({
        url: "/reserve2/user-reserve/cancel",
        method: "post",
        data
    })
}

// 获取项目 预约时间
export function ReserveGetDates(data) {
    return request({
        url: "/reserve2/get-dates",
        method: "post",
        data
    })
}

// 获取项目 预约信息
export function ReserveGetInfo(data) {
    return request({
        url: "/reserve2/get",
        method: "post",
        data
    })
}


// 获取项目 预约添加
export function reserveAdd(data) {
    return request({
        url: "/reserve2/user-reserve/add",
        method: "post",
        data
    })
}
