<template>
    <div class="reserve-time">
        <div class="time-calendar">
            <calendar :reserveDate="reserveDate" v-if="calendarShow" :setprojectResult="resultProject" :startTime="startTime" :endTime="endTime" />
        </div>
        <div class="time-project" v-if="activeProject.projectOTime">
            <div class="time-item" v-for="(item,index) in activeProject.projectOTime" :key="index" @click="item.usedQuota < item.quota?activeId = item.timeRangeId : ''" :class="item.usedQuota == item.quota?'noAbout':activeId == item.timeRangeId?'timeActive':''">
                <p class="time-section">{{item.timeRangeStartTime}}-{{item.timeRangeEndTime}}</p>
                <p class="Quota">
                    ({{item.usedQuota}}/{{item.quota}})
                </p>
                <p class="Quota">
                    {{item.usedQuota>=item.quota?'已约满':'可以预约'}}
                </p>
            </div>
        </div>
        <div class="bottom-btn" >
            <p class="btn" @click="goReserveForm" :class="{'btnAcive':activeId}">下一步</p>
        </div>
        <van-overlay :show="agreementShow"  class-name="agreement-overlay">
            <div class="agreement-container" >
                <div class="section" v-if="reserveObj.protocol">
                    <p class="title">
                        必读须知
                    </p>
                    <div class="sect-txt" v-html="reserveObj.protocol.replace(/\n/g, '<br/>')">

                    </div>
                </div>
                <div class="btns" >
                    <p class="txt" :class="{'activebtn':time==0}" @click="time == 0?agreementShow=false:''">
                        我知道了
                        <span v-if="time!=0">({{time}}s)</span>
                    </p>
                </div>
            </div>
        </van-overlay>
    </div>
</template>

<script>
import calendar from "./components/calendar.vue";
import { ReserveGetDates, ReserveGetInfo } from "@/api/appointment";
export default {
    components:{ calendar },
    data () {
        return {
            reserveId:this.$route.query.reserveId,
            projectId:this.$route.query.projectId,
            reserveDate:[],
            startTime:null,
            time:3,
            endTime:null,
            calendarShow:false,
            activeProject:{},
            activeId:null,
            agreementShow:false,
            reserveObj:{}
        }
    },
    created () {
        document.title = "预约时间";
        this.getreserveInfo();
        this.getreserveDates();
        let times = setInterval(()=>{
            this.time --;
            if(this.time==0){
                clearInterval(times)
            }
        },1000)
    },
    methods: {
        getreserveInfo(){
            ReserveGetInfo({
                reserveId:this.reserveId
            }).then(res =>{
                this.reserveObj = res.data.data;
                this.agreementShow = !res.data.data.protocol?false:true;
            })
        },
        goReserveForm(){
            if(this.activeId){
                this.$router.push({
                    path:"/appointment/summitReserve",
                    query:{
                        reserveId:this.reserveId,
                        projectId:this.projectId,
                        timeRangeId:this.activeId,
                        timeRangeId:this.activeId,
                        dateRangeId:this.activeProject.dateRangeId,
                        dateRangeDate:this.activeProject.dateRangeDate
                    }
                })
            }
        },
        getreserveDates(){
            ReserveGetDates({
                reserveId:this.reserveId,
                projectId:this.projectId
            }).then(res =>{
                console.log(res);
                if(res.data.errorCode == 0){
                    let data = res.data.data;
                    for(let i in data){
                        let it = data[i];
                        let r = this.reserveDate.findIndex(Item => Item.dateRangeDate == it.dateRangeDate);
                        if(!this.startTime){
                            this.startTime = it.dateRangeDate;
                        }else{
                            if(new Date(this.startTime).getTime()>new Date(it.dateRangeDate).getTime()){
                                this.startTime = it.dateRangeDate;
                            }
                        }
                        if(!this.endTime){
                            this.endTime = it.dateRangeDate;
                        }else{
                            if(new Date(this.endTime).getTime()<new Date(it.dateRangeDate).getTime()){
                                this.endTime = it.dateRangeDate;
                            }
                        }

                        if(r!=-1){
                            this.reserveDate[r].quota += it.quota;
                            this.reserveDate[r].usedQuota += it.usedQuota;
                            this.reserveDate[r].projectOTime.push(it);
                        }else{
                            let item = {
                                projectOTime:[it],
                                ...it,
                            };
                            this.reserveDate.push(item);
                        }
                            
                    }
                    console.log(this.reserveDate);
                    this.calendarShow = true;
                }
            })
        },
        resultProject(project){
            this.activeId = null;
            this.activeProject = project;
        }
    }
}
</script>

<style lang="less" scoped>
.reserve-time{
    overflow: auto;
    height: 100%;
    box-sizing: border-box;
    background: #F5F6FA;
    padding-bottom: 2.8rem;
    position: relative;
    .time-calendar{
        margin: 0.4rem 0;
        background: #fff;
    }
    .time-project{
        margin-bottom: 0.4rem;
        padding: 0.6rem;
        background: #fff;
        overflow: hidden;
        .time-item{
            width: 32%;
            margin-right: 1.5%;
            height: 3.1rem;
            float: left;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            border-radius: 0.16rem;
            margin-bottom: 0.2rem;
            border: 0.02rem solid #A8A8AB;
            &:nth-child(3n){
                margin-right: 0;
            }
            .time-section{
                font-size: 0.64rem;
                font-weight: 500;
                color: #666666;
                line-height: 0.9rem;
            }
            .Quota{
                font-size: 0.56rem;
                font-weight: 500;
                color: #909399;
                line-height: 0.8rem;
            }
        }
        .timeActive{
            border: 0.02rem solid #3E8EFA;
            position: relative;
            &::after{
                width: 0.8rem;
                height: 0.8rem;
                position: absolute;
                right: 0;
                bottom: 0;
                content: ' ';
                background: url("~@/assets/img/reserve-active-time-icon.png") no-repeat;
                background-size: 100% 100%;
            }
            .time-section,.Quota{
                color: #3E8EFA;
            }
        }
        .noAbout{
            background: #EBEEF5;
            border: 0;
        }
    }
    .bottom-btn{
        position: fixed;
        left: 0;
        bottom: 0;
        background: #FFFFFF;
        width: 100%;
        height: 3rem;
        box-shadow: 0 -0.08rem 0.3rem 0 rgba(199,199,199,0.5);
        border-radius: 0.06rem;
        display: flex;
        align-items: center;
        justify-content: center;
        .btn{
            width: 11.2rem;
            height: 1.76rem;
            line-height: 1.76rem;
            border-radius: 1rem;
            text-align: center;
            background: #EBEEF5;
            color: #909399;
            font-size: 0.76rem;
        }
        .btnAcive{
            background: linear-gradient(180deg, #49BFFF 0%, #499FFF 100%);
            color: #fff;
        }
    }
}
.agreement-overlay{
    display: flex;
    align-items: center;
    justify-content: center;
    .agreement-container{
        height: max-content;
        width: 12rem;
        padding: 0 0.66rem 0.66rem;
        background: url("~@/assets/img/agreement-back.png") no-repeat;
        background-size: 100% 7.4rem;
        background-position: bottom;
        .section{
            // width: 100%;
            margin: 0 0.1rem;
            padding: 0.6rem 0.8rem;
            box-sizing: border-box;
            background: #f5f7f8;
            border-radius: 0.36rem;
            // border-radius: ;
            .title{
                font-size: 0.72rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 600;
                color: #333333;
                line-height: 1rem;
                text-align: center;
            }
            .sect-txt{
                font-size: 0.64rem;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
                line-height: 1rem;
                min-height: 2rem;
                max-height: 8rem;
                overflow: auto;
            }
        }
        .btns{
            // height: 1.4rem;
            margin: 0.6rem 0;
            margin-top: 1.8rem;
            .txt{
                width: 7.6rem;
                height: 1.6rem;
                background: linear-gradient(180deg, #49BFFF 0%, #499FFF 100%);
                border-radius: 0.8rem;
                line-height: 1.6rem;
                text-align: center;
                margin: 0 auto;
                opacity: 0.4;
                font-size: 0.76rem;
                font-weight: 400;
                color: #FFFFFF;
            }
            .activebtn{
                opacity: 1;
            }
        }
    }
}
</style>